import revive_payload_client_Neo02lrgR1 from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_j3yphneguknhnmvf6bslhoozbq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_zgz8yBGe78 from "/app/node_modules/.pnpm/vuetify-nuxt-module@0.18.3_magicast@0.3.5_rollup@4.22.4_typescript@5.6.2_vite@5.4.7_@types+no_efbipnbesqbtwm4hsknon6xpg4/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_hx0CEcSZtp from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_j3yphneguknhnmvf6bslhoozbq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_rX3sHrOKFo from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_j3yphneguknhnmvf6bslhoozbq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_0e4sDHWC8k from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_j3yphneguknhnmvf6bslhoozbq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_l0horBT4m3 from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_j3yphneguknhnmvf6bslhoozbq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_6mreF8Wsqb from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_j3yphneguknhnmvf6bslhoozbq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_AYK2ykYAOr from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_j3yphneguknhnmvf6bslhoozbq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_4rmdAavpIi from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_j3yphneguknhnmvf6bslhoozbq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import vuetify_no_client_hints_TEHCFfEtGt from "/app/node_modules/.pnpm/vuetify-nuxt-module@0.18.3_magicast@0.3.5_rollup@4.22.4_typescript@5.6.2_vite@5.4.7_@types+no_efbipnbesqbtwm4hsknon6xpg4/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import vuetify_7h9QAQEssH from "/app/plugins/vuetify.ts";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/app/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  revive_payload_client_Neo02lrgR1,
  vuetify_icons_zgz8yBGe78,
  unhead_hx0CEcSZtp,
  router_rX3sHrOKFo,
  payload_client_0e4sDHWC8k,
  navigation_repaint_client_l0horBT4m3,
  check_outdated_build_client_6mreF8Wsqb,
  chunk_reload_client_AYK2ykYAOr,
  components_plugin_KR1HBZs4kY,
  prefetch_client_4rmdAavpIi,
  vuetify_no_client_hints_TEHCFfEtGt,
  vuetify_7h9QAQEssH,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]