import { defineNuxtPlugin } from "#imports";
import { createVuetify } from "vuetify";
import "vuetify/styles";
import { aliases, mdi } from "vuetify/iconsets/mdi-svg";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

export default defineNuxtPlugin((nuxtApp) => {
  const setPharusTheme = {
    dark: false,
    colors: {
      background: "#FFFFFF",
      base: "#FFFFFF",
      surface: "#FFFFFF",
      primary: "#372044",
      secondary: "#FF0038",
      alt: "#8c52ad",
      softPrimary: "#f3f1f4",
      softSecondary: "#d194a1",
      purple: "#a770c2",
      error: "#B00020",
      info: "#2196F3",
      success: "#4CAF50",
      warning: "#FB8C00",
      action: "#177ccf",
      card: "#fc043c",
      courseContent: "rgba(55, 32, 68, 0.4);",
      courseContentdHover: "rgba(252, 4, 60, 0.4)",
    },
  };
  const vuetify = createVuetify({
    icons: {
      defaultSet: "mdi",
      aliases,
      sets: { mdi },
    },
    theme: {
      defaultTheme: "setPharusTheme",
      themes: {
        setPharusTheme,
      },
    },
    components,
    directives,
  });
  nuxtApp.vueApp.use(vuetify);
});
