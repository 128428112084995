import { default as cursosuo9DUg06k2Meta } from "/app/pages/admin/cursos.vue?macro=true";
import { default as indexi8nCATZISJMeta } from "/app/pages/admin/index.vue?macro=true";
import { default as _91position_935x2JksRzpdMeta } from "/app/pages/contenido/[syllabus]/[position].vue?macro=true";
import { default as favoritosCHmHcLYlMvMeta } from "/app/pages/favoritos.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91link_93as3NXGRRJQMeta } from "/app/pages/invite/[link].vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as logoutHaVXWqg8UAMeta } from "/app/pages/logout.vue?macro=true";
import { default as _91pensum_93coXdto60RMMeta } from "/app/pages/pensum/[pensum].vue?macro=true";
import { default as perfilyxUn0VW310Meta } from "/app/pages/perfil.vue?macro=true";
import { default as portalNhUh4tjzEoMeta } from "/app/pages/portal.vue?macro=true";
import { default as _91subject_932ECgxiDhjXMeta } from "/app/pages/recursos/[subject].vue?macro=true";
import { default as _91topic_93EhNfBYGrlmMeta } from "/app/pages/tema/[topic].vue?macro=true";
export default [
  {
    name: "admin-cursos",
    path: "/admin/cursos",
    component: () => import("/app/pages/admin/cursos.vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/app/pages/admin/index.vue")
  },
  {
    name: "contenido-syllabus-position",
    path: "/contenido/:syllabus()/:position()",
    component: () => import("/app/pages/contenido/[syllabus]/[position].vue")
  },
  {
    name: "favoritos",
    path: "/favoritos",
    component: () => import("/app/pages/favoritos.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "invite-link",
    path: "/invite/:link()",
    meta: _91link_93as3NXGRRJQMeta || {},
    component: () => import("/app/pages/invite/[link].vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginhHM0vSTW5jMeta || {},
    component: () => import("/app/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logoutHaVXWqg8UAMeta || {},
    component: () => import("/app/pages/logout.vue")
  },
  {
    name: "pensum-pensum",
    path: "/pensum/:pensum()",
    component: () => import("/app/pages/pensum/[pensum].vue")
  },
  {
    name: "perfil",
    path: "/perfil",
    component: () => import("/app/pages/perfil.vue")
  },
  {
    name: "portal",
    path: "/portal",
    component: () => import("/app/pages/portal.vue")
  },
  {
    name: "recursos-subject",
    path: "/recursos/:subject()",
    component: () => import("/app/pages/recursos/[subject].vue")
  },
  {
    name: "tema-topic",
    path: "/tema/:topic()",
    component: () => import("/app/pages/tema/[topic].vue")
  }
]